//import tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

//config
@import "~normalize.css/normalize.css";
@import "./keyframes.scss";
@import "./fonts.scss";

//base
@layer base {
  html {
    @apply text-white bg-black;
    overflow-x: hidden;
  }

  body {
    min-width: 375px;
    @apply bg-blue-charcoal;
  }

  .gradient {
    &-1 {
      background: linear-gradient(90deg,
          rgba(255, 255, 255, 0.75) -4.38%,
          #bcb7e6 52.41%,
          rgba(255, 255, 255, 0.75) 104.66%);
    }

    &-2 {
      background: linear-gradient(77.99deg, #ffffff -15.34%, #7c76a9 117.9%);
    }

    &-3 {
      background: linear-gradient(180deg, rgba(6, 12, 25, 0) 0%, #050a19 100%);
    }

    &-4 {
      background: linear-gradient(311.63deg, #080e24 29.54%, #0a3a6a 74.97%);
    }

    &-5 {
      background: linear-gradient(311.63deg, #040817 29.54%, #334659 74.97%);
    }

    &-6 {
      background: linear-gradient(61.25deg, #0d0c1a -6.83%, #4f4b73 121.02%);
      box-shadow: inset 0px 4px 34px #0b3a6b;
    }

    &-7 {
      background: linear-gradient(122.5deg,
          #ffffff 23.06%,
          rgba(255, 255, 255, 0.5) 95.95%);
    }

    &-8 {
      background: linear-gradient(90deg,
          rgba(255, 255, 255, 0.75) -4.38%,
          #bcb7e6 52.41%,
          rgba(255, 255, 255, 0.75) 104.66%);
    }

    &-9 {
      background: linear-gradient(108.25deg,
          #060c19 15.14%,
          rgba(6, 12, 25, 0.3) 115.63%);
    }
  }
}

.invest-button {
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: top 0.4s;
  margin-top: 30px;
  text-wrap: nowrap;
  width: 100% !important;
  align-items: center;
  max-width: none;
  z-index: 99;

  &:before {
    content: "";
    background: linear-gradient(332.73deg, #1e0c0b 34.02%, #0d1525 70.97%);
    position: absolute;
    z-index: -1;
    right: 0;
    left: 0;
    aspect-ratio: 1/1;

    @screen lg {
      aspect-ratio: none;
    }

  }

  span {
    background: linear-gradient(122.5deg,
        #ffffff 23.06%,
        rgba(255, 255, 255, 0.5) 95.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &:hover {
    &:before {
      animation: buttonhover 2s linear infinite;
    }
  }

  &:not(.footarea) {
    padding: 14px 25px;
    width: 145px;
    line-height: 19px;

    @screen sm {
      line-height: 34px;
    }

    @screen md {
      padding: 14px 50px;
      min-width: auto;
      margin-top: 50px;
    }

    @screen lg {
      padding: 28px 55px;
    }

    @screen xl {
      max-width: 260px !important;
    }
  }

  &.footarea {
    padding: 5.5px;
    line-height: 34px;
    font-size: 18px;
    min-width: 96px;
    border-radius: 16px;

    @screen lg {
      padding: 8px;
    }

    @screen xl {
      padding: 14px;
      font-size: 22px;
      min-width: 145px;
      border-radius: 30px;
    }
  }
}

.gradient-text {
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
}

.is-invalid {
  position: relative;

  input,
  select,
  textarea {
    border-color: #ff6161 !important;
  }

  &-message {
    color: #ff6161;
    font-size: 10px;
    position: absolute;
    bottom: -17px;
    left: 0;

    @screen md {
      font-size: 13px;
      bottom: -22px;
    }
  }
}

@media screen and (max-width: 425px) {
  .section-height {
    height: calc(100vh + 110px) !important;
  }
}