.animated {
  animation-duration: 0.7s;
  animation-fill-mode: both;
}

@for $i from 1 through 9 {
  .animated-delay-#{$i} {
    animation-delay: #{$i}00ms;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes buttonhover {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateInfinite {
  to {
    transform: rotate(360deg);
  }
}
.rotateInfinite {
  animation: rotateInfinite 22s linear infinite;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.zoomIn {
  animation-name: zoomIn;
}

///clear

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes introZoom {
  from {
    opacity: 0;
    transform: scale(2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.introZoom {
  animation-name: introZoom;
  animation-duration: 1s;
  animation-fill-mode: both;
  [data-testid="layer-0"] {
    inset: 0 !important;
  }
}

@keyframes introTextLeft {
  from {
    opacity: 0;
    transform: translate3d(-200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.introTextLeft {
  animation-name: introTextLeft;
}

@keyframes introTextRight {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.introTextRight {
  animation-name: introTextRight;
}

.svg-animate {
  @apply stroke-amethyst-smoke;
  opacity: 0.8;
  stroke-width: 2px;
  stroke-dasharray: -68;
  stroke-dashoffset: -68;
  animation: line 1.8s linear infinite;

  @keyframes line {
    to {
      stroke-dashoffset: 0;
    }
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeInLeftO75 {
  from {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }

  to {
    opacity: 0.75;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeftO75 {
  animation-name: fadeInLeftO75;
}

@keyframes fadeInLeft50 {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 0.75;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft50 {
  animation-name: fadeInLeft50;
}

@keyframes fadeInRight50 {
  from {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 0.75;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight50 {
  animation-name: fadeInRight50;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}
.zoomOut {
  animation-name: zoomOut;
}
