header {
    min-height: 140px;
}

.header-hidden {
    transform: translateY(-100%);
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;

    &.header-hidden {
        transform: translateY(-100%);
    }

    .switch-border {
        padding: 8.5px 8px;
        border-radius: 20px;
        margin-left: auto;
        margin-right: 40px;
        position: relative;
        max-width: 136px;
        width: 100%;
        height: 63px;
        overflow: hidden;
        background: url(../../assets/images/language-border.svg) no-repeat center center;

        .switch {
            display: flex;
            cursor: pointer;
            overflow: hidden;
            background-color: transparent;
            border-radius: 20px;
            height: 100%;
            -webkit-tap-highlight-color: transparent;


            .en,
            .tr {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: bold;
                transition: color 0.3s ease-in-out;
                position: relative;
                z-index: 2;
                align-self: center;
                background: linear-gradient(122.5deg, #FFFFFF 23.06%, rgba(255, 255, 255, 0.5) 95.95%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .en {
                color: rgba(5, 12, 25, 1);
                background: none;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;

            }

            .slider {
                width: 60px; // half of the switch width
                height: 46px;
                background: #fff;
                transition: transform 0.3s ease-in-out;
                position: absolute;
                z-index: 1;
                border-radius: 13px;
            }

            &.active {

                .en {
                    background: linear-gradient(122.5deg, #FFFFFF 23.06%, rgba(255, 255, 255, 0.5) 95.95%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .tr {
                    color: rgba(5, 12, 25, 1) !important;
                    background: none;
                    -webkit-background-clip: unset;
                    -webkit-text-fill-color: unset;

                }

                .slider {
                    transform: translateX(60px);
                }
            }
        }

    }


}

@media screen and (max-width: 768px) {
    header {
        min-height: 70px;

        .switch-border {
            padding: 6.5px 6px;
            margin-right: 20px;
            margin-top: 20px;
            max-width: 136px;
            width: 100%;
            height: 47px;
            background-size: 1080px 768px;
            margin-top: 20px;

            .switch {
                max-width: 96px;
                margin: auto;
                align-items: center;
                padding: 2px 5px;

                .slider {
                    width: 44px;
                    height: 32px;
                }

                &.active {
                    .slider {
                        transform: translateX(42px);
                    }
                }
            }
        }
    }
}