.successModal {
  background: rgba(0, 0, 0, 0.3);
  &-container {
    max-width: 720px;
    width: calc(100% - 30px);
    background: linear-gradient(311.63deg, #0d1525 29.54%, #1e2d3d 74.97%);
    box-shadow: 0px 14px 44px rgba(46, 29, 58, 0.51);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    z-index: 1;
    @screen md {
      border-radius: 30px;
    }
  }
  &-send-btn {
    background: linear-gradient(
      332.73deg,
      #d3d3d3 34.02%,
      #ffffff 70.97%
    ) !important;
    border-radius: 15px;
  }
  &-close {
    width: 20px;
    height: 20px;
    span {
      width: 19px;
      height: 4px;
      border-radius: 4px;
      position: absolute;
      background-color: white;
      right: 0;
      top: 8px;
      transition: 0.4s;
      &:first-child {
        transform: rotate(-45deg);
      }
      &:last-child {
        transform: rotate(45deg);
      }
    }
    &:hover {
      span {
        &:first-child {
          transform: rotate(45deg);
        }
        &:last-child {
          transform: rotate(315deg);
        }
      }
    }
  }
}
